import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Box,
} from "@mui/material";
import * as apiDashBoardService from "../../../services/apidashboardservice";

const CreateTicket = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [errorStage, setErrorStage] = useState("");
  const [errorDetails, setErrorDetails] = useState("");
  const [ticketSubject, setTicketSubject] = useState("");
  const [apiName, setApiName] = useState("");
  const [endpointAddress, setEndpointAddress] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [fileData, setFileData] = useState({ fileName: "", fileExtension: "", fileContent: "" });
  const [createdTicketNumber, setCreatedTicketNumber] = useState(null);

  const steps = ["Error Stage", "Error Details", "Review and Submit"];

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setFileData({
        fileName: file.name.split(".")[0],
        fileExtension: `.${file.name.split(".").pop()}`,
        fileContent: e.target.result.split(",")[1], // Base64 data
      });
    };

    reader.readAsDataURL(file);
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      let errorType;
      switch (errorStage) {
        case "An API":
          errorType = 1;
          break;
        case "The Sandbox":
          errorType = 2;
          break;
        case "Production":
          errorType = 3;
          break;
        case "Other":
          errorType = 4;
          break;
        default:
          errorType = 0;
      }

      const ticketData = {
        ticketNumber: null,
        errorType,
        status: "O",
        apiName,
        endpointAddress,
        subject: ticketSubject,
        description: errorDetails,
        errorCode,
        fileName: fileData.fileName,
        fileExtension: fileData.fileExtension,
        fileData: fileData.fileContent,
      };

      try {
        const response = await apiDashBoardService.createTicket(
          ticketData.ticketNumber,
          ticketData.errorType,
          ticketData.status,
          ticketData.apiName,
          ticketData.endpointAddress,
          ticketData.subject,
          ticketData.description,
          ticketData.errorCode,
          ticketData.fileName,
          ticketData.fileExtension,
          ticketData.fileData
        );

        // Gelen ticketNumber'ı state'e kaydet
        setCreatedTicketNumber(response.data.ticketNumber);

        // Formu sıfırla
        setErrorStage("");
        setErrorDetails("");
        setTicketSubject("");
        setApiName("");
        setEndpointAddress("");
        setErrorCode("");
        setFileData({ fileName: "", fileExtension: "", fileContent: "" });
        setActiveStep(activeStep + 1);
      } catch (error) {
        console.error("Error creating ticket:", error);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setErrorStage("");
    setErrorDetails("");
    setTicketSubject("");
    setApiName("");
    setEndpointAddress("");
    setErrorCode("");
    setFileData({ fileName: "", fileExtension: "", fileContent: "" });
    setCreatedTicketNumber(null);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <FormControl component="fieldset">
            <Typography variant="h6">At which stage are you getting an error?</Typography>
            <RadioGroup
              value={errorStage}
              onChange={(e) => setErrorStage(e.target.value)}
            >
              <FormControlLabel value="An API" control={<Radio />} label="An API" />
              <FormControlLabel value="The Sandbox" control={<Radio />} label="The Sandbox" />
              <FormControlLabel value="Production" control={<Radio />} label="Production" />
              <FormControlLabel value="Other" control={<Radio />} label="Other" />
            </RadioGroup>
          </FormControl>
        );
      case 1:
        return (
          <Box>
            <TextField
              label="Error Details"
              value={errorDetails}
              onChange={(e) => setErrorDetails(e.target.value)}
              fullWidth
              multiline
              rows={4}
              margin="normal"
            />
            <TextField
              label="API Name"
              value={apiName}
              onChange={(e) => setApiName(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Endpoint Address"
              value={endpointAddress}
              onChange={(e) => setEndpointAddress(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Subject"
              value={ticketSubject}
              onChange={(e) => setTicketSubject(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Error Code"
              value={errorCode}
              onChange={(e) => setErrorCode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="outlined" component="label" sx={{ marginTop: "10px" }}>
              Upload File
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h6">Review your information:</Typography>
            <Typography>
              <strong>Error Stage:</strong> {errorStage}
            </Typography>
            <Typography>
              <strong>API Name:</strong> {apiName}
            </Typography>
            <Typography>
              <strong>Endpoint Address:</strong> {endpointAddress}
            </Typography>
            <Typography>
              <strong>Subject:</strong> {ticketSubject}
            </Typography>
            <Typography>
              <strong>Error Code:</strong> {errorCode}
            </Typography>
            <Typography>
              <strong>Error Details:</strong> {errorDetails}
            </Typography>
            <Typography>
              <strong>File:</strong> {fileData.fileName || "No file uploaded"}
            </Typography>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column'
    }}>
    <Box sx={{ width: "50%" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Support Tickets
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ marginTop: "20px" }}>
        {activeStep === steps.length ? (
          <Box textAlign="center">
            <Typography variant="h6" color="primary">
              ✅ Request Submitted
            </Typography>
            <Typography variant="body1" sx={{ marginTop: "10px" }}>
              Your request has been sent. Your ticket number is: {createdTicketNumber || "N/A"}. You will be contacted as soon as possible.
              You can check your ticket from the{" "}
              <a
                href="/dashboard?page=myTickets"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                My Tickets
              </a>{" "}
              section and view it anytime after it has been answered.
            </Typography>
            <Button
              onClick={handleReset}
              variant="contained"
              sx={{ marginTop: "20px" }}
            >
              Create Another Ticket
            </Button>
          </Box>
        ) : (
          <Box>
            {renderStepContent(activeStep)}
            <Box sx={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                onClick={handleNext}
                variant="contained"
                color="primary"
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
    </div>
  );
};

export default CreateTicket;
