import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../../redux/actions';
import styles from './style.js';
import './style.css';
import MUTypography from '../ui/MUTypography';
import { motion } from 'framer-motion';
import Button from '../ui/Button';
import Header from '../Header';
import Contact from '../Contact';
import { history } from '../../App';

class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Header></Header>

                <div style={{ position: 'relative' }}>
                    <img style={{ width: '100%' }} src={require('../../images/support.png')} alt="support" />
                    <div
                        style={{
                            position: 'absolute',
                            top: '20%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#FFF',
                            textAlign: 'center',
                            zIndex: 1,
                        }}
                    >
                        <MUTypography className={classes.developerBlogTitle}>WE ARE HERE FOR YOU</MUTypography>
                        <MUTypography className={classes.developerBlogText}>Choose the type of help that is best for you.</MUTypography>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '70%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex',
                            width: 'calc(90%)',
                            margin: '20px',
                        }}
                    >
                        <div style={{ flex: 1, backgroundColor: '#FFF', marginRight: '10px', padding: '56px 36px',cursor:'pointer' }} onClick={()=>history.push('dashboard?page=createTicket')}>
                            <MUTypography className={classes.typography}>
                                SUPPORT
                                <br>
                                </br>TICKET
                            </MUTypography>
                            <MUTypography className={classes.typographyText}>
                                In this section, you can<br>
                                </br> create a support ticket <br>
                                </br>and track its progress.
                            </MUTypography>
                        </div>
                        <div style={{ flex: 1, backgroundColor: '#FFF', marginRight: '10px', padding: '56px 36px' ,cursor:'pointer'}} onClick={()=>history.push('faq')}>
                            <MUTypography className={classes.typography}>
                                FAQ
                            </MUTypography>
                            <MUTypography className={classes.typographyText}>
                                You can find frequently<br />
                                asked questions and their <br />
                                answers in the FAQ section,<br />
                                which includes previously<br />
                                asked and likely questions.
                            </MUTypography>
                        </div>
                        <div style={{ flex: 1, backgroundColor: '#FFF', marginRight: '10px', padding: '56px 10px',cursor:'pointer' }}  onClick={()=>{const url = 'https://join.slack.com/t/albarakaapi/shared_invite/zt-24pex8dlv-htHYqVb4SdZkM9~DI1fPWQ';
    window.open(url, '_blank')}}>
                            <MUTypography className={classes.typography}>
                                DEVELOPER
                                <br>
                                </br>CHANNEL
                            </MUTypography>
                            <MUTypography className={classes.typographyText}>
                                Join our Slack channel and<br />
                                ask any questions you have<br></br> in mind, seeking the help of<br></br> our support team and <br>
                                </br>experienced developers.
                            </MUTypography>
                        </div>
                    </div>
                </div>
                <Contact/>
                
            </div>

        );
    }
}

Support.defaultProps = {
    showHeader: true,
};
Support.propTypes = {
    login: PropTypes.object.isRequired,
    showHeader: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    login: state.login,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
});

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Support);
