import React from 'react';
import { connect } from 'react-redux';
import { Link as LiNavLink } from 'react-router-dom';
import './style.css';
import styles from './style.js';
import { withStyles } from '@material-ui/core/styles';
import { history } from '../../App';
import { compose, bindActionCreators } from 'redux';
import * as Actions from '../../redux/actions';
import { Avatar, Menu, MenuItem, IconButton, Typography as MUTypography, ListItemIcon } from '@material-ui/core';
import classNames from 'classnames';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import * as apiUserService from '../../services/confirm';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      firstname: '',
    };
  }

  componentDidMount() {
    // Kullanıcının giriş yapıp yapmadığını kontrol ediyoruz
    const { login } = this.props;
    if (login && login.token) {
      this.getUser();
    }
  }
  

  getUser() {

    apiUserService.getUser()
      .then((responseJson) => {
        if (responseJson.data) {
          let user = responseJson.data;

          console.log('get user success');
          this.setState({
            firstname: user.firstName,
            lastname: user.lastName,
            emailaddress: user.eMailAddress,
            phonenumber: user.phoneNumber,
            country: user.countryCode
          });
        }
      })
      .catch((exception) => {
        console.log('get user error');
        let errorMessage = '' + exception;

        this.props.actions.setNotification(errorMessage, 'error');
      })
      .finally(()=>{
        this.setState({ onServiceCall: false });
      });
  }

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogoutClick = () => {
    this.handleMenuClose();
    this.props.actions.logout();
  };

  onAvatarClick() {
    history.push('/profile');
  }

  render() {
    const { classes, login, homepage } = this.props;
    const { anchorEl, firstname } = this.state;
    const isMenuOpen = Boolean(anchorEl);
    const username = firstname || login.username || login.email;

    return (
      <nav className="navbar navbar-expand-md navbar-dark fixed-top p-md-5 m-sm-0">
        <LiNavLink to='/'>
          <img src={this.props.srcImage} alt="Canvas Logo" />
        </LiNavLink>
        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" style={{ fontSize: '20px' }}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse" id="navbarCollapse" aria-expanded="false" style={{ display: 'flex !important' }}>
          <ul id="headermenu" className="d-sm-flex">
            <ul id="sitemenu" className="navbar-nav mr-auto">
              <LiNavLink activeClassName='active' exact={true} to="/gettingStarted">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  GETTING STARTED
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/productCard">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  DISCOVER APIS
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/docs" >
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  DOCUMENTATIONS
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/community">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  COMMUNITY
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/support">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  SUPPORT
                </MUTypography>
              </LiNavLink>
              <LiNavLink activeClassName='active' exact={true} to="/faq">
                <MUTypography className={this.props.homepage ? classes.menu : classes.typography}>
                  FAQ
                </MUTypography>
              </LiNavLink>
            </ul>

            <ul className="navbar-nav float-md-right float-sm-left">
              <li className="nav-item float-right">
                {login.token && username !== undefined ? (
                  <div>
                    <IconButton onClick={this.handleMenuOpen}>
                      <Avatar className={classNames('outlinedPrimary', classes.orangeAvatar)}>
                        {username.charAt(0).toUpperCase()}
                      </Avatar>
                      <MUTypography className={homepage ? classes.menu : classes.typography}>
                        Hello, {username}
                      </MUTypography>
                      <ArrowDropDownIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={isMenuOpen}
                      onClose={this.handleMenuClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      getContentAnchorEl={null}
                    >
                      <MenuItem onClick={this.handleMenuClose} component={LiNavLink} to="/dashboard?page=home">
                        <ListItemIcon>
                          <DashboardIcon />
                        </ListItemIcon>
                        Dashboard
                      </MenuItem>
                      <MenuItem onClick={this.onLogoutClick} component={LiNavLink} to="/">
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        Sign out
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <LiNavLink to="/login">
                    <MUTypography className={homepage ? classes.menu : classes.typography}>
                      SIGN IN
                    </MUTypography>
                  </LiNavLink>
                )}
              </li>
              {!login.token && (
                <li className="nav-item float-right">
                  <LiNavLink to="/register">
                    <MUTypography className={homepage ? classes.menu : classes.typography}>
                      SIGN UP
                    </MUTypography>
                  </LiNavLink>
                </li>
              )}
            </ul>
          </ul>
        </div>
      </nav>
    );
  }
}

Header.defaultProps = {
  srcImage: require('../../images/albaraka_api.jpg')
};

const mapStateToProps = state => ({
  login: state.login.toJS()
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
