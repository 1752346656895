const styles = theme => ({
  menuList: {
    backgroundColor: 'rgba(255, 255, 255, 0.62)',
    width: '100%',
    padding: 0,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Arial, sans-serif',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'rgba(80, 227, 194, 0.1)',
    },
  },
  selected: {
    backgroundColor: '#50e3c2 !important',
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },
  },
  primary: {
    color: theme.palette.common.black,
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Arial, sans-serif',
  },
  icon: {
    fontSize: '20px',
    color: theme.palette.common.black,
    marginRight: '8px',
  },
  dashboardRoot: {
    margin: 0,
    padding: 0,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: '0px', 
  },
  content: {
    margin: '0 auto',
    padding: 0,
    width: '100%',
    maxWidth: '1200px',
  },
});

export default styles;
