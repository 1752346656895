const styles = theme => ({
  textField: {
    width: '100%',
    transform: 'none !important',
    marginBottom: '20px',
  },
  menu: {
    width: 200,
  },
  registerButton: {
    marginLeft: theme.spacing.unit,
    marginTop: '20px',
    display: 'flex',
  },
  profileRoot: {
    flexGrow: 1,
    padding: '20px 0', // İçerik boşluğu düzenlendi
    margin: '0 auto', // Ortalanmış içerik
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  title: {
    fontSize: '24px',
  },
  formControl: {
    width: '33.95%',
    marginLeft: '2.5%',
  },
  select: {
    fontFamily: 'Gilroy',
    fontSize: '12px',
    padding: '4%',
    backgroundColor: 'white !important',
    borderRadius: '28px !important',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    width: '100%',
    height: '57px',
  },
});

export default styles;
