import { SERVER_BASE_URL } from '../config/paths';
import { put, post, get, postPreLogin} from './request';

const call = {
  get(path, payload, onError) {
    return get(SERVER_BASE_URL + path);
  },
  getJson(path, payload, onError) {
    return get(SERVER_BASE_URL + path);
  },
  post(path, payload, onError) {
    return post(SERVER_BASE_URL + path, payload);
  },
  put(path, payload, onError) {
    return put(SERVER_BASE_URL + path, payload);
  },
  postBeforeLogin(path, payload, onError, timeout = 20000) {
    return postPreLogin(SERVER_BASE_URL + path, payload, true, timeout);
  }
};

export {
  call
};
