import React from 'react';

const DashboardHome = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '20px',
        backgroundColor: '#F8F9FA',
      }}
    >
      {/* Sol tarafta PNG */}
      <div style={{ textAlign: 'center', marginRight: '20px' }}>
        <img
          src="static/images/control_panel_bro.png" 
          alt="Dashboard Animation"
          style={{ width: '300px', height: 'auto', borderRadius: '8px' }}
        />
      </div>

      <div
        style={{
          backgroundColor: '#AEEBF2',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '400px',
        }}
      >
        <h2 style={{ color: '#004D61', fontWeight: 'bold', marginBottom: '10px' }}>
          Simplifying Support and App Management
        </h2>
        <p style={{ color: '#004D61', fontSize: '16px', lineHeight: '1.5', margin: 0 }}>
          Create, track, and control with ease.
        </p>
      </div>
    </div>
  );
};

export default DashboardHome;
