import React from 'react';
import MUGrid from '../ui/MUGrid';
import Applications from './Applications';
import NewApplication from '../NewApplication';

class MyApplications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applications: true,
      newapplication: false,
      editapplication: false,
      appId: '',
      readOnly: false,
    };
  }

  resetState = () => {
    this.setState({
      applications: true,
      newapplication: false,
      editapplication: false,
      appId: '',
      readOnly: false,
    });
  };

  handleClick = (e, component) => {
    this.resetState();
    this.setState({
      [component]: true,
      editapplication: false,
    });
  };

  onCardClick = (id) => {
    console.log('card clicked in applications');
    this.setState({
      applications: false,
      newapplication: false,
      editapplication: true,
      appId: id,
    });
  };

  render() {
    const { applications, newapplication, editapplication } = this.state;

    return (
      <MUGrid container spacing={24}>
        <MUGrid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
          {/* Başlık ve Açıklama */}
          <h1 style={{ fontSize: '2rem', fontWeight: 'bold', marginBottom: '10px' }}>
            My Applications
          </h1>
          <p style={{ fontSize: '1rem', color: '#666' }}>
            Via My Applications page, you can create new applications or you can edit existing applications.
          </p>
        </MUGrid>
        <MUGrid item xs={3}>
          {/* Menü burada olabilir veya MyApplications'dan gönderilebilir */}
        </MUGrid>
        <MUGrid item xs={9}>
          <Applications
            open={applications}
            {...this.props}
            onCardClick={this.onCardClick}
            onAddClick={(e) => this.handleClick(e, 'newapplication')}
          />
          {editapplication ? (
            <NewApplication
              open={editapplication}
              editapplication={editapplication}
              newapplication={newapplication}
              {...this.props}
              {...this.state}
            />
          ) : null}
          {newapplication ? (
            <NewApplication
              open={newapplication}
              editapplication={newapplication}
              newapplication={newapplication}
              {...this.props}
              {...this.state}
            />
          ) : null}
        </MUGrid>
      </MUGrid>
    );
  }
}

export default MyApplications;
